import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    listData:[],   //首页课程的数据
    dialogFormVisible:false
  },
  getters: {
  },
  mutations: {
    saveListData(state,newlistData){
      state.listData = newlistData
    },
    changeDialog(state,dialog){
      state.dialogFormVisible = dialog
    }
  },
  actions: {
  },
  modules: {
  }
})
