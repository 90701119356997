
<template>
  <div class="jyb-focus">
    <!-- 轮播图 -->
    <swiper class="mySwiper" :options="swiperOptions" v-if="images.length">
      <swiper-slide v-for="(item, index) in images" :key="index">
        <!--  :src="'https://clo.litaifs.com/'+item.image" -->
        <img class="mySwiperwrapperimg" :src="'https://clo.litaifs.com/'+item.image"/>
      </swiper-slide>
    </swiper>
  </div>
</template>
     <script>
import { bannerList } from "@/api/home.js";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: { swiper, swiperSlide },

  data() {
    return {
      fileHost: "https://clo.litaifs.com/",
      images: [
      ],
      swiperOptions: {
        // 基本配置
        direction: "horizontal", // 滑动方向，可以是'horizontal'或'vertical'
        loop: true, // 是否循环
        autoplay: {
          delay: 8000, // 自动播放间隔
          disableOnInteraction: false // 用户操作后是否停止自动播放
        },
        // 分页器配置
        pagination: {
          clickable: true // 分页器是否可点击
        },
        // 导航按钮配置
        navigation: {
          nextEl: ".swiper-button-next", // 下一项元素
          prevEl: ".swiper-button-prev" // 上一项元素
        },
        // 滚动条配置
        scrollbar: {
          el: ".swiper-scrollbar" // 滚动条元素
        }
      },
    };
  },
  created() {
    this.banner()
  },
  computed: {
  
  },
 
  methods: {
    //查询banner列表
    banner() {
      bannerList().then(res => {
        if (res.code == 200) {
           this.images =res.data
        }
      });
    },
    getstyle(img) {
      return {
        background: "url(" + "https://clo.litaifs.com/" + img + ")",
        backgroundSize: "100% 100%",
        backgroundRepeat: " no-repeat"
      };
    },
  
  }
};
</script>
     <style lang="scss">
.jyb-focus {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .mySwiper {
      width: 100%;
      min-width: 1300px;
      height: 100%;
      .mySwiperwrapperimg {
        width:100%;
        min-width: 1300px;
        height: 100%;
        margin: auto;
        background-size: 100%;
      }
    }
}
</style>