//引入
import axios from "./axios.js"; //路劲自己修改
//首页  
export const indexList = (params) => {
    return axios({
        url: "home/company/index_list",
        method: "post" ,  
        data: params
    })
}
//分类列表
export const menuList = (params) => {
    return axios({
        url: "home/company/menu_list",
        method: "post" ,  
        data: params
    })
}
// 子分类列表
export const companyIndex = (params) => {
    return axios({
        url: "home/company/index",
        method: "post" ,  
        data: params
    })
}
// 文件列表
export const companyList = (params) => {
    return axios({
        url: "home/company/list",
        method: "post" ,  
        data: params
    })
}
// 文件详情
export const detail = (params) => {
    return axios({
        url: "home/company/detail",
        method: "post" ,  
        data: params
    })
}
// 登录
export const Login = (params) => {
    return axios({
        url: "home/company/login",
        method: "post" ,  
        data: params
    })
}
// 修改学习状态
export const update = (params) => {
    return axios({
        url: "home/company/status_update",
        method: "post" ,  
        data: params
    })
}
// banner列表
export const bannerList = (params) => {
    return axios({
        url: "home/company/banner_list",
        method: "post" ,  
        data: params
    })
}
// banner详情
export const bannerDetail = (params) => {
    return axios({
        url: "home/company/banner_detail",
        method: "post" ,  
        data: params
    })
}
// 样衣图库列表
export const shoolLIst = (params) => {
    return axios({
        url: "home/company/school_list",
        method: "post" ,  
        data: params
    })
}
// 样衣图库列表详情
export const uniformList = (params) => {
    return axios({
        url: "home/company/uniform_list",
        method: "post" ,  
        data: params
    })
}
// 查询链接 urls
export const urls_list = (params) => {
    return axios({
        url: "home/company/urls",
        method: "post" ,  
        data: params
    })
}
//查询合作案例，样衣图片详情的接口
export const uniformDetails = (params) => {
    return axios({
        url: "home/company/uniform_details",
        method: "post" ,  
        data: params
    })
}

                   
