
<template>
  <div class="centerWidth">
    <!-- 头部 -->
    <div
      class="header"
      :style="{ backgroundColor: headerColor }"
      :class="headerWidth?'max_width':'min_width'"
    >
      <div class="fx_center" :class="headerWidth?'headerWidthone':'headerWidthtow'">
        <img alt class="imgicon" src="@/assets/1.png" />
        <div class="fex">
          <div v-for="(item,indx) in list" :key="indx">
            <div class="mr">
              <el-dropdown v-if="item.child.length>0" @command="handletabCommand">
                <div class="tabliat">{{ item.title }}</div>
                <el-dropdown-menu
                  slot="dropdown"
                  class="header-new-drop"
                  v-show="item.child.length>0"
                >
                  <el-dropdown-item
                    v-for="(items,index) in item.child"
                    :key="index"
                    :command="items"
                  >{{ items.title }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <div class="tabliat" v-else @click="changegohome(item)">{{ item.title }}</div>
            </div>
          </div>
          <div class="fex">
            <div class="fex" style="margin-left: 15px;" v-if="Tokenshow">
              <img src="@/assets/32.png" alt class="headPortrait" />
              <div v-if="this.$route.path == '/'">
                <el-dropdown @command="handleCommand">
                  <div
                    style="color: rgba(255, 255, 255, 1);  cursor: pointer;min-width:50px"
                  >{{userinfo.name}}</div>
                  <el-dropdown-menu slot="dropdown" class="header-new-drop">
                    <el-dropdown-item>退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div v-else @click="denglu" class="login">登录</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部内容-->
    <router-view></router-view>
    <!-- 登录弹窗 -->
    <el-dialog
      title="欢迎登录"
      :visible.sync="$store.state.dialogFormVisible"
      width="350px"
      center
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item>
          <el-input placeholder="账号" prefix-icon="el-icon-user-solid" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="密码" prefix-icon="el-icon-s-goods" v-model="form.password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="quxiao">取 消</el-button>
        <el-button type="primary" @click="Login">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

  <script>
import { menuList, Login, indexList } from "@/api/home.js";
export default {
  data() {
    return {
      routeId: "", //路由id
      headerColor: "", //头部的背景颜色
      headerWidth: Boolean,
      active: 0,
      userinfo: {}, //用户登录信息
      keywords: "",
      list: [],
      Tokenshow: false,
      form: {
        name: "",
        password: ""
      }
    };
  },
  watch: {
    // 监听路由变化，如果路由变化了，则更新头部背景颜色
    $route(to, from) {
      this.headerColor = to.meta.headerColor || "";
      this.routeId = to.query.childid;
      if (to.meta.requiresAuth) {
        this.headerWidth = true;
      } else {
        this.headerWidth = false;
      }
    }
  },
  created() {
    if (localStorage.getItem("Token")) {
      this.Tokenshow = true;
      this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    }
    this.menuListList();
    this.headerColor = this.$route.meta.headerColor || ""; // 如果没有指定颜色，则默认为白色
    this.headerWidth = this.$route.meta.requiresAuth;
  },
  methods: {
    // 回到首页
    changegohome(item) {
      if (item.title == "首页") {
        if (this.$route.path !== "/") {
          this.$router.push("/");
        } else {
          return;
        }
      }
    },
    // 退出登录
    handleCommand() {
      this.$confirm("是否退出登录", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (this.$route.path !== "/") {
            this.$router.push("/");
          } else {
            return;
          }
          localStorage.removeItem("Token");
          localStorage.removeItem("userinfo");
          location.reload();
        })
        .catch(() => {});
    },
    denglu() {
      this.$store.commit("changeDialog", true);
    },
    // 查询顶部分类列表
    menuListList() {
      menuList().then(res => {
        if (res.code == 200) {
          this.list = res.data;
        }
      });
    },
    // 登录
    Login() {
      let body = {
        account: this.form.name,
        password: this.form.password
      };
      if (this.form.name || this.form.password) {
        Login(body).then(res => {
          if (res) {
            if (res.code === 200) {
              localStorage.setItem("Token", res.data.id);
              localStorage.setItem("userinfo", JSON.stringify(res.data));
              this.$message.success("登录成功");
              this.userinfo = res.data;
              setInterval(() => {
                this.$store.commit("changeDialog", false);
                location.reload();
              }, 500);
            } else if (res.code == 201) {
              localStorage.removeStorageSync("token");
              localStorage.removeStorageSync("unifo");
              this.$message.error(res.msg);
            } else if (res.code == 401) {
              localStorage.removeStorageSync("token");
              localStorage.removeStorageSync("unifo");
              this.$message.error(res.msg);
              return;
            }
          }
        });
      } else {
        this.$message({
          message: "请输入账号或密码",
          type: "warning"
        });
      }
    },

    indexList(keywords) {
      indexList({ keywords: keywords }).then(res => {
        if (res.code == 200) {
          this.$store.commit("saveListData", res.data);
        }
      });
    },
    // 取消登录
    quxiao() {
      this.$store.commit("changeDialog", false);
    },
    // 头部的点击事件
    handletabCommand(command) {
      let Token = localStorage.getItem("Token");
      if (!Token) {
        this.$store.commit("changeDialog", true);
      } else {
        if (command.title == "样衣图库") {
          if (this.routeId == command.id) {
            console.log("000");
            return;
          } else {
            this.$router.push({
              path: "/SampleClothes",
              query: {
                childid: command.id,
                name: command.title
              }
            });
          }
          // 跳转合作案例页面
        } else if (command.id == 12) {
          if (this.routeId == command.id) {
            console.log("000");
            return;
          } else {
            this.$router.push({
              path: "/cooperate",
              query: {
                childid: command.id,
                name: command.title
              }
            });
          }
        } else {
          // 判断跳转的页面是不是当前页面
          if (this.routeId == command.id) {
            return;
          } else {
            this.$router.push({
              path: "/college",
              query: {
                childid: command.id,
                name: command.title
              }
            });
          }
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.header-new-drop {
  ::v-deep div {
    // 注意：这里转深度书写样式修改它的 div 下方 after 样式
    &:after {
      // border: 2px solid  rgba(212, 48, 48, 1);
      border-bottom-color: rgba(212, 48, 48, 1) !important;
    }
  }
}
.el-dropdown-menu {
  background-color: rgba(212, 48, 48, 1) !important;
  border: 0px solid rgba(212, 48, 48, 1) !important;
  border-radius: 5px;
}
.el-dropdown-menu__item {
  color: rgba(255, 255, 255, 1) !important;
  border-color: rgba(212, 48, 48, 1);
  line-height: 40px !important;
  padding: 0 26px !important;
  &:hover {
    background-color: rgba(204, 204, 204, 0.5) !important;
    color: rgba(255, 255, 255, 1) !important;
  }
}
</style>